import * as React from 'react';

const CheckMarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = props.className;
  return (
    // tslint:disable:max-line-length
    <svg viewBox="0 0 57 60" {...props} className={classes}>
      <path
        fill="currentColor"
        d="M22.98,59.3c-1.31,0-2.58-0.5-3.54-1.42L1.57,40.7c-2.03-1.95-2.1-5.19-0.14-7.22
        c1.96-2.04,5.19-2.1,7.22-0.14L21.93,46.1l24.9-40.83c1.47-2.41,4.62-3.17,7.02-1.7c2.41,1.47,3.17,4.61,1.7,7.02L27.34,56.85
        c-0.81,1.32-2.17,2.21-3.71,2.41C23.42,59.28,23.2,59.3,22.98,59.3z"
      />
    </svg>
    // tslint:enable:max-line-length
  );
};

export default CheckMarkIcon;
