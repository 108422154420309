import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './TechnologySection.scss';
const technologyImageWidescreen = require('../../assets/img/frogo-technology-adtech-widescreen.svg');
const technologyImageSmartphone = require('../../assets/img/frogo-technology-adtech-smartphone.svg');

const TechnologySection = () => {
  return (
    <section className="TechnologySection">
      <Container>
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center TechnologySection-technology-image-text-color">
              Google Ads Integration
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12} className="text-center py-4">
            <picture>
              <source
                srcSet={technologyImageWidescreen}
                type="image/svg+xml"
                media="(min-width: 1024px)"
                className="TechnologySection-technology-image"
              />
              <source
                srcSet={technologyImageSmartphone}
                type="image/svg+xml"
                className="TechnologySection-technology-image"
              />
              <img
                src={technologyImageWidescreen}
                className="TechnologySection-technology-image"
                alt="Technologie"
              />
            </picture>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TechnologySection;
