import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import SwappingNav from '../components/SwappingNav';
import TopNavbar from '../components/TopNavBar';
import FrogoJumbotron from '../components/Landingpage/FrogoJumbotron';
import ContextFactorSection from '../components/Landingpage/ContextFactorSection';
import CustomerValueSection from '../components/Landingpage/CustomerValueSection';
import TechnologySection from '../components/Landingpage/TechnologySection';
import PrincipleSection from '../components/Landingpage/PrincipleSection';
import ExampleSection from '../components/Landingpage/ExampleSection';
import Footer from '../components/Footer';
import IeWarning from '../components/IeWarning';

const metaKeywords =
  'frogo ad, frogoad, data driven marketing, wetter targeting, weather triggered ads,' +
  ' contextual ads, contextual targeting, programmatic advertising, kontext targeting, adwords optimierung';

const metaDescription =
  'Mit FROGO AD Online-Marketing-Kampagnen im Umgebungskontext steuern.' +
  ' Lokal. Datengetrieben. Automatisiert.' +
  ' Verbesseren Sie Ihre Conversion-Rate und steigern Sie Ihren Return On Advertising Spend (ROAS) ➚.' +
  ' Jetzt Angebot anfragen!';

export default class extends React.Component<{}, {}> {
  public render() {
    return (
      <Layout>
        <Helmet>
          <meta name="keywords" content={metaKeywords} />
          <meta name="description" content={metaDescription} />
          {/* Verification for Google Webmasters Tool */}
          <meta
            name="google-site-verification"
            content="LDi1_IEMa7f2XDUX1PgHDoltfpTtfnI8W3B8npJdRQ0"
          />
        </Helmet>
        <SwappingNav
          scrollInHeight={15}
          initialNavbar={<TopNavbar transparent={true} />}
          swappedNavbar={<TopNavbar />}
        />
        <main>
          <FrogoJumbotron />
          <ContextFactorSection />
          <ExampleSection />
          <PrincipleSection />
          <TechnologySection />
          <CustomerValueSection />
          <Footer />
        </main>
        <IeWarning />
      </Layout>
    );
  }
}
