import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import CheckMarkIcon from '../CheckMarkIcon';

import './FrogoJumbotron.scss';

import Slant from '../Slant';

const introScreen = require('../../assets/img/frogo-landingpage-intro-screen.svg');

const FrogoJumbotron = () => {
  return (
    <section className="FrogoJumbotron text-white">
      <Container>
        <Row className="pt-2">
          <Col xs={12} md={6}>
            <p className="lead text-left text-md-right">
              <img
                src={introScreen}
                alt="Intro-Screen"
                className="img-fluid FrogoJumbotron-intro-screen"
              />
            </p>
          </Col>
          <Col xs={12} md={6} className="pt-4">
            <h6 className="mb-0">FROGO AD</h6>
            <h2 className="mb-0">Context-Driven-Targeting</h2>
            <p className="lead FrogoJumbotron-text">
              Wir fühlen uns für den optimalen Einsatz Ihres Google Ads
              Werbebudgets verantwortlich. Mit FROGO&nbsp;AD können Sie daher{' '}
              <span className="FrogoJumbotron-text-highlighted">
                Google Ads passend zur Umgebung
              </span>{' '}
              Ihrer Zielgruppe aussteuern. Durch die verbesserte
              Zielgruppenansprache steigt Ihre Conversion-Rate nachhaltig.
            </p>
            <ul className="FrogoJumbotron-sub-text FrogoJumbotron-icon-list">
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Effizienterer Budgeteinsatz
              </li>
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Verbesserte Brand Awareness
              </li>
              <li>
                <CheckMarkIcon className="pr-1" height="1em" />
                Höherer Return-on-Investment
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#ecf2f6' }} />
    </section>
  );
};

export default FrogoJumbotron;
