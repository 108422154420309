import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText
} from 'reactstrap';

import { Link } from 'gatsby';

import './ContextFactorSection.scss';
const contextWeatherImg = require('../../assets/img/frogo-context-weather-box.svg');
const contextHolidayImg = require('../../assets/img/frogo-context-holidays-box.svg');
const contextRegionImg = require('../../assets/img/frogo-context-region-time-box.svg');

const ContextFactorSection = () => {
  return (
    <section id="context-factors" className="ContextFactorSection">
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <h5 className="ContextFactorSection-headline text-uppercase">
              Umgebungs&shy;faktoren im Online-Marketing
            </h5>
            <p className="pt-2 lead ContextFactorSection-content">
              Umgebungs­&shy;faktoren wie Wetter, Region, Feiertage und Zeit
              haben starken Einfluss auf das Konsumverhalten. Diese prägen
              entscheidend die Nachfrage nach vielen Produkten und
              Dienstleistungen.
            </p>
          </Col>
        </Row>
        <Row className="pt-3 equal-height">
          <Col xs={12} lg={true}>
            <Card className="ContextFactorSection-card weather card-equal-height">
              <div className="ContextFactorSection-card-image-wrapper">
                <CardImg
                  src={contextWeatherImg}
                  className="ContextFactorSection-card-image weather"
                  alt="Kontextfaktor Wetter"
                />
              </div>
              <CardBody>
                <CardTitle>Wetter</CardTitle>
                <CardText>
                  Jahreszeiten, lokale Wetterzustände (wie Regen, Sonnenschein
                  oder Nebel), aber auch z.B. niedrige Temperaturen haben
                  Auswirkungen auf die Stimmung der Verbraucher und damit auf
                  deren Kaufverhalten.
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={true}>
            <Card className="ContextFactorSection-card holiday card-equal-height">
              <CardImg
                src={contextHolidayImg}
                className="ContextFactorSection-card-image holiday"
                alt="Kontextfaktor Ferien/Feiertage"
              />
              <CardBody>
                <CardTitle>Ferien/Feiertage</CardTitle>
                <CardText>
                  Vor, während und nach Feiertagen ändern sich die Bedürfnisse
                  vieler Kunden entscheidend. Das Interesse an vielen Artikeln
                  wächst oder verringert sich.
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={true}>
            <Card className="ContextFactorSection-card region-time card-equal-height">
              <CardImg
                src={contextRegionImg}
                className="ContextFactorSection-card-image region-time"
                alt="Kontextfaktor Region/Zeit"
              />
              <CardBody>
                <CardTitle>Region/Zeit</CardTitle>
                <CardText>
                  Das Kaufinteresse variiert häufig nicht nur in Abhängigkeit
                  von einer bestimmten Region. Auch in Bezug auf Tageszeit und
                  Wochentag lassen sich deutliche Abweichungen erkennen.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-left">
            <p className="pt-2 lead ContextFactorSection-content">
              FROGO AD bietet eine Datenan&shy;alyse, die den Einfluss von
              Umgebungs&shy;faktoren wie z.B. Wetter, Regionen oder Zeit auf
              Ihre Google&nbsp;Ads Kampagnen sichtbar macht. Dazu werden Ihre
              historischen Performancedaten mit 22 unterschiedlichen
              Umgebungsfaktoren korreliert.
            </p>
            <p>
              <Link to="/analyse" className="btn btn-secondary text-white">
                Mehr erfahren
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContextFactorSection;
