import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './CustomerValueSection.scss';
const ctrIcon = require('../../assets/img/frogo-icon-customer-value-ctr.svg');
const conversionrateIcon = require('../../assets/img/frogo-icon-customer-value-conversionrate.svg');
const budgetIcon = require('../../assets/img/frogo-icon-customer-value-budget.svg');
const roasIcon = require('../../assets/img/frogo-icon-customer-value-roas.svg');

const CustomerValueSection = () => {
  return (
    <section className="CustomerValueSection text-white">
      <Container>
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center CustomerValueSection-text-color">
              Ihr Optimierungspotential
            </h5>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center text-sm-left">
                <img
                  src={ctrIcon}
                  alt="Click-Through-Rate-Icon"
                  className="CustomerValueSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2>Höhere Click-Through-Rate (CTR)</h2>
                <p className="lead CustomerValueSection-text-color">
                  Ihre Anzeigen haben eine höhere Relevanz für Ihre Zielgruppe.
                  Dadurch steigt die{' '}
                  <b className="text-white">Klickrate (CTR)</b>.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center text-sm-left">
                <img
                  src={conversionrateIcon}
                  alt="Conversion-Rate-Icon"
                  className="CustomerValueSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2>Verbesserung der Conversion-Rate</h2>
                <p className="lead CustomerValueSection-text-color">
                  Durch den optimalen Kontextbezug ist die Wahrscheinlichkeit
                  für eine Conversion höher. Die{' '}
                  <b className="text-white">Conversion-Rate</b> Ihrer Kampagnen{' '}
                  <b className="text-white">verbessert</b> sich.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-sm-5">
          <Col xs={12} md={6} className="pt-4 pr-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center text-sm-left">
                <img
                  src={budgetIcon}
                  alt="Conversion-Rate-Icon"
                  className="CustomerValueSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2>Effizienter Budgeteinsatz</h2>
                <p className="lead CustomerValueSection-text-color">
                  Mit FROGO AD werden Ihre Gebote gesteigert, wenn Ihre
                  Zielgruppe eine erhöhte Kaufbereitschaft zeigt, und reduziert,
                  sobald Ihre Anzeigen weniger relevant sind. Damit investieren
                  Sie ihr <b className="text-white">Budget</b> besonders{' '}
                  <b className="text-white">effizient</b>.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="pt-4 pl-lg-4">
            <Row>
              <Col xs={12} lg={3} className="pr-lg-0 text-center text-sm-left">
                <img
                  src={roasIcon}
                  alt="Conversion-Rate-Icon"
                  className="CustomerValueSection-icon"
                />
              </Col>
              <Col xs={12} lg={9} className="pl-lg-0">
                <h2>Steigerung des Return On Advertising Spend (ROAS) </h2>
                <p className="lead CustomerValueSection-text-color">
                  Sie erhalten mehr Anzeigenklicks, die am Ende zu Conversions
                  führen. Damit erzielen Sie mit gleichem Werbebudget eine{' '}
                  <b className="text-white">höhere Rendite</b>.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CustomerValueSection;
