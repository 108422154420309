import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './ExampleSection.scss';

const cloudImage = require('../../assets/img/frogo-productexample-clouds.svg');
const combsImage = require('../../assets/img/frogo-productexample-combs.svg');
const combsDetailImage = require('../../assets/img/frogo-productexample-combs-detail.svg');
const phoneImage = require('../../assets/img/frogo-productexample-phone.svg');
const wallImage = require('../../assets/img/frogo-productexample-wall.svg');

const ExampleSection = () => {
  return (
    <section>
      <div className="ExampleSection-image-container ExampleSection-bg-image d-ie-none">
        <img src={cloudImage} className="ExampleSection-clouds" alt="Wolken" />
        <img src={combsImage} className="ExampleSection-combs" alt="Waben" />
        <img
          src={combsDetailImage}
          className="ExampleSection-combs-detail"
          alt="Waben (Detail)"
        />
        <img
          src={phoneImage}
          className="ExampleSection-phone"
          alt="iPhone mit Urlaubsangebot"
        />
        <img src={wallImage} className="ExampleSection-wall" alt="Wand" />
      </div>
      <div className="ExampleSection-text-container">
        <Container>
          <Row>
            <Col xs={12}>
              <h5 className="text-uppercase text-center ExampleSection-text-color">
                Context-Driven-Targeting
              </h5>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xs={12} sm={6}>
              <p className="lead text-left text-sm-right ExampleSection-text-color">
                Mit FROGO AD lassen sich Ihre{' '}
                <b className="ExampleSection-text-color-highlight">
                  Online-Marketing-Kampagnen{' '}
                </b>
                mit dem Umgebungs&shy;kontext Ihrer Ziel&shy;gruppe in
                <b className="ExampleSection-text-color-highlight">
                  {' '}
                  perfekten Einklang bringen.
                </b>
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <p className="lead text-left text-sm-left ExampleSection-text-color">
                Adressieren Sie Ihre Kunden zu{' '}
                <b className="ExampleSection-text-color-highlight">
                  optimalen Zeit&shy;punkten
                </b>{' '}
                am
                <b className="ExampleSection-text-color-highlight">
                  {' '}
                  richtigen Ort
                </b>{' '}
                mit
                <b className="ExampleSection-text-color-highlight">
                  {' '}
                  relevanten Werbe&shy;botschaften.
                </b>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ExampleSection;
