import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Slant from '../Slant';

import './PrincipleSection.scss';
const principleDiagram = require('../../assets/img/frogo-diagram.svg');

interface CircleProps {
  fillColor: string;
}

const Circle = (props: CircleProps) => {
  return (
    <svg height="1em" width="1em" style={{ verticalAlign: '-0.15em' }}>
      <circle cx="0.5em" cy="0.5em" r="0.5em" fill={props.fillColor} />
    </svg>
  );
};

const PrincipleSection = () => {
  return (
    <section className="PrincipleSection">
      <Container>
        <div className="PrincipleSection-legend">
          <div>
            <Circle fillColor="#343A8D" />
            <span className="PrincipleSection-legend-text">
              <b>Kaufwahrscheinlichkeit</b>{' '}
              <small>in Abhängigkeit von Umgebung</small>
            </span>
          </div>
          <div>
            <Circle fillColor="#4bb9ae" />
            <span className="PrincipleSection-legend-text">
              <b>Werbepräsenz</b> <small>gesteuert durch FROGO AD</small>
            </span>
          </div>
        </div>
      </Container>
      <div className="PrincipleSection-diagram-wrapper">
        <img
          src={principleDiagram}
          alt="FROGO AD Prinzip"
          className="PrincipleSection-diagram"
        />
      </div>
      <Container className="PrincipleSection-content">
        <Row>
          <Col xs={12}>
            <h5 className="text-uppercase text-center text-primary">
              Das FROGO AD Prinzip
            </h5>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs={12} sm={6}>
            <p className="lead text-left text-sm-right">
              Die Kaufbereitschaft Ihrer Zielgruppe ändert sich häufig in
              Abhängigkeit vom Umgebungskontext. Mit FROGO AD können Sie Ihre
              Online-Marketing-Kampagnen <b className="text-primary">optimal</b>{' '}
              und
              <b className="text-primary"> vollautomatisch</b> an verschiedenste
              Umgebungs&shy;konstellationen anpassen.
            </p>
          </Col>
          <Col xs={12} sm={6}>
            <p className="lead text-left text-sm-left">
              Spreche Sie Ihre Zielgruppe in dem Moment{' '}
              <b className="text-primary">verstärkt</b> mit{' '}
              <b className="text-primary">relevanten Anzeigen</b> an, in dem die
              Kaufwahrscheinlich&shy;keit besonders hoch ist. Ist der
              Umgebungskontext Ihrer Kunden ungünstig, lässt sich die{' '}
              <b className="text-primary">Werbepräsenz verringern</b>.
            </p>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#11222c' }} />
    </section>
  );
};

export default PrincipleSection;
