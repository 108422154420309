import * as React from 'react';
import { UncontrolledAlert } from 'reactstrap';

import './IeWarning.scss';

const IeWarning = () => {
  return (
    <UncontrolledAlert color="warning" className="IeWarning show">
      Diese Seite wird mit dem Internet Explorer nicht richtig dargestellt.
      Bitte verwende eine aktuelle Version von{' '}
      <a
        href="https://www.google.de/chrome/browser/desktop/index.html"
        target="_blank"
        rel="noopener"
        className="alert-link"
      >
        Chrome
      </a>
      ,{' '}
      <a
        href="https://www.mozilla.org/de/firefox/new/"
        target="_blank"
        rel="noopener"
        className="alert-link"
      >
        Firefox
      </a>{' '}
      oder{' '}
      <a
        href="https://www.microsoft.com/de-de/windows/microsoft-edge"
        target="_blank"
        rel="noopener"
        className="alert-link"
      >
        Microsoft Edge
      </a>
      .
    </UncontrolledAlert>
  );
};

export default IeWarning;
